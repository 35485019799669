
.card {
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 1px 23px 0 rgb(0 0 0 / 6%);
    border-radius: 12px;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    padding: 40px 100px;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
}
.columns{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media screen  and (max-width: 500px) {
    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        padding: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 0 solid rgba(0,0,0,.125);
    }
    .columns{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
}

.gutters-sm {
    margin-right: -8px;
    margin-left: -8px;
}

.gutters-sm>.col, .gutters-sm>[class*=col-] {
    padding-right: 8px;
    padding-left: 8px;
}
.mb-3, .my-3 {
    margin-bottom: 1rem!important;
}

.bg-gray-300 {
    background-color: #e2e8f0;
}
.h-100 {
    height: 100%!important;
}
.shadow-none {
    box-shadow: none!important;
}



.width{
    width: 200px;
}